<template>
  <div class="home">
     <!-- <My v-if="ishowmy" :phone='phone'></My> -->
     <!-- <memberHint v-if="ishowmemberHint" :membertit='membertit'></memberHint> -->
    <el-container>

      <el-container>

        <el-header>
          <div style="display:flex;">
          <div style="margin-right: 30px;font-size: 20px;">京东财务系统</div>
          <el-menu
           router
  :default-active="$route.path"
  mode="horizontal"
  @select="handleSelect"
  background-color="#333333"
  text-color="#fff"
  active-text-color="#ffd04b">
  <el-menu-item @click="fn" index="/fan-economyone">门店管理</el-menu-item>
  <el-menu-item index="/fan-economytwo">统计分析</el-menu-item>
  <el-menu-item index="/tb_order" v-show="zong_zfb_import==1">支付宝流水</el-menu-item>
  <el-menu-item index="/fan-economyExport">功能汇总</el-menu-item>
  <!-- <el-menu-item >余额{{money}}</el-menu-item> -->
  <!-- <el-submenu index="2">
    <template slot="title">{{vip_user}}</template>
    <el-menu-item @click="mycenter">个人中心</el-menu-item>
  </el-submenu> -->
</el-menu>
          </div>
         <div style="display:flex;">
          <div class="nickname">{{phone}}</div>
         <!-- <a @click="tapxinjianFn()" href="javascript:;" class="logout" style="display: inline-block;margin-right:30px;">修改密码</a> -->
		<a @click="logout" href="javascript:;" class="logout">退出</a>
          </div>
        </el-header>

        <el-main>
          <!-- 配置子路由的出口 -->
          <router-view ></router-view>
        </el-main>
      </el-container>
    </el-container>
	<resetpsd v-if="isshowsetpsd"></resetpsd>
  </div>
</template>

<script>
	import resetpsd from '../common/setPsd'
export default {
	components:{
		resetpsd
	},
  mounted(){
    this.money=localStorage.getItem('money')
  },
  data () {
    return {
      phone: JSON.parse(localStorage.getItem('phone')) ,
      money:0,
      vip_user:'',
      headimgurl:'',
      // dateKey:new Date().getTime(),
		isshowsetpsd:false,
		zong_zfb_import:0,
  }},
  created(){
		this.limitsInfo();
  },
  methods: {
	//获取权限信息
	async limitsInfo(){
		const res= await this.$axios.post('/jdfms/zong_limits_info')
		console.log(res)
		if(res.data.status!=1){
			return this.$message.error(res.data.tips)
		}else{
			this.zong_zfb_import=res.data.message.zong_zfb_import
		}
	},
	tapxinjianFn(){
		this.isshowsetpsd=true
	},
    fn(){
		console.log(new Date().getTime())
    },
      handleSelect(key, keyPath) {
        console.log(key, keyPath);
        // try{
        
        //  let m=this;
        // m.dateKey=new Date()
        // }catch(err){
        // console.log(err)
        // }
      },

    async logout () {
      try {
        // 添加确认提示框
        await this.$confirm('你确认要退出本系统么?', '温馨提示', {
          type: 'warning'
        })
        localStorage.removeItem('token')
        localStorage.removeItem('phone')
        this.$router.push('/login')
      } catch (err) {
        console.log(err)
      }
    },
    mycenter(){
      this.ishowmy=true
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
  .el-container {
    height: 100%;
    .el-header {
      background-color: #333333;
      color: white;
      text-align: center;
      line-height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        margin: 0 0 0 50px;
        
      }
      .nickname {
        margin: 0 30px;
      }
      .logout {
        color: orange;
        
      }
    }

    .el-aside {
      background-color: #545c64;
      // text-align: center;
      .title {
        height: 60px;
        line-height: 60px;
        background-color: #333;
        color: #fff;
        font-weight: bold;
        font-size: 18px;
      }
      .el-menu {
        border-right: none;
      }
    }

    .el-main {
      background-color: #E9EEF3;
    }
  }
}
</style>

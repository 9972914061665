<template>
  <div class="big_box"  @click="closemy">
    <div class="from_box"  @click.stop="">
     <div class="box_second" style="margin-top:30px">
		<div class="second_top">
			<div class="top_tit">手机号</div>
			<el-input style="width:300px;" v-model="phone" placeholder="请输入手机号"></el-input>
		</div>
     <div class="second_top">
       <div class="top_tit">旧密码</div>
       <el-input style="width:300px;" type="password" v-model="oldPsd" placeholder="请输入旧密码"></el-input>
     </div>
	<div class="second_top">
       <div class="top_tit">新密码</div>
       <el-input style="width:300px;" type="password" v-model="newPsd" placeholder="请输入新密码"></el-input>
     </div>
     <div style="display: flex;justify-content: space-around;margin-top:20px;">
       <el-button @click="closemy" type="info">取消</el-button>
       <el-button @click="taotwotijiao" type="success">提交</el-button>
     </div>
   </div>
      </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
			oldPsd:'',
			newPsd:'',
			phone:''
        }
    },
     methods:{
		async taotwotijiao(){
			if(!this.phone){
				return this.$message.error('请填写手机号')
			}
			///^1[3456789]\d{9}$/
			let phoneReg=/^1[3456789]\d{9}$/.test(this.phone);
			if(!phoneReg){
				return this.$message.error('请输入正确的手机号')
			}
			if(!this.oldPsd){
				return this.$message.error('请填写旧密码')
			}
			if(!this.newPsd){
				return this.$message.error('请填写新密码')
			}
			let newPsdStr=this.newPsd;
			let reg=/^\d{3,11}\b/.test(newPsdStr);
			if(!reg){
				return this.$message.error('新密码必须是3-11位的数字')
			}
			
			let info = {
				phone:this.phone,
				oldPsd:this.$md5(this.oldPsd+'yd'),
				newPsd:this.$md5(this.newPsd+'yd'),
				type:1
			}
			console.log(info)
			const res =await  this.$axios.post('/logon/reset_psd',{info})
			if(res.data.status==1){
				this.$message.success(res.data.tips)
				this.$parent.isshowsetpsd=false
				//成功后，直接登录
				localStorage.setItem('token', res.data.message.token)
				localStorage.setItem('phone', res.data.message.phone)
				this.$router.push('/home')
			}else{
				this.$message.error(res.data.tips)
			}
		},
         closemy(){
           this.$parent.isshowsetpsd=false
         }
     }
}
</script>

<style lang="scss" scoped>
.big_box{
       width: 100%;
       height: 100%;
       position: fixed;
       top: 0;
       left: 0;
       z-index: 999;
       display: flex;
       align-items: center;
       justify-content: center;
		background-color: rgba(0,0,0,0.6);
       
    .from_box{
           width: 550px;
           height: 300px;
           background-color: #fff;
            .box_tit{
            padding: 20px;
            background-color: #f8f8f8;
            .el-icon-close{
                float:right;
                width: 30px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                font-size: 15px;
            }
        } 


        .box_second{
          .second_top{
			margin:5px 60px;
            display: flex;
            align-items: center;
            padding: 10px 0;
            .top_tit{
               margin: 0 20px;
            }
          }
        }

    }
}
</style>

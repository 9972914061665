<template>
<div class="outerbox">
  <div class="login">
    <div class="login_icon">
    <div class="icon iconfont icon-ren img"></div>
    <div class="icon iconfont icon-suo img"></div>
    </div>
<el-form ref="form" :model="form" label-width="80px" :rules="rules">
  <el-form-item label="" prop='username'>
    <el-input v-model="form.username" placeholder="请输入账号"></el-input>
  </el-form-item>
    <el-form-item label="" prop='password'>
    <el-input  type="password" v-model="form.password" placeholder="请输入密码"></el-input>
  </el-form-item>
   <el-form-item class="none_bottom">
		<el-button @click="tapxinjianFn()" class="login-btn">修改密码</el-button>
        <el-button @click="reset" class="login-btn">重置</el-button>
        <el-button @click="login" style="background-color: rgba(41, 202, 55, 0.333);"  type="primary">登录</el-button>
      </el-form-item>
  </el-form>
  </div>
 <resetpsd v-if="isshowsetpsd"></resetpsd> 
  </div>
		
</template>

<script>
 import resetpsd from '../common/setPsd' 
export default {
	components:{
		resetpsd
	},
  data () {
    return {
      form: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入手机号', trigger: ['blur'] },
          { pattern:/^1[3456789]\d{9}$/, message: '请输入正确的手机号', trigger: ['blur'] }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: ['blur', 'change'] },
          { pattern: /^\d{3,11}$/, message: '密码必须是3-11位的数字', trigger: ['blur', 'change'] }
        ]
      },
		isshowsetpsd:false,
    }
  },
  methods: {
	tapxinjianFn(){
		this.isshowsetpsd=true
	},
    async login () {
        try {
        await this.$refs.form.validate()
        // 发送请求
        const info={
          phone:this.form.username,
          password:this.$md5(this.form.password+'yd')
        }
           console.log(info)
			
        const res =await this.$axios.post('/logon/logon',{info}).then(response => (this.info = response))
		console.log(res)
        if(res.data.status==1){
          console.log(res,'返回')
          this.$message.success('登录成功')
          localStorage.setItem('token', res.data.message.token)
          localStorage.setItem('phone', res.data.message.phone)
          this.$router.push('/home')
        }else{
          this.$message.error(res.data.tips)
        }
      } catch (error) {
      }
    },
    // 点击注册
    reset () {
      this.$refs.form.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
.outerbox{
  background-color: pink;
  padding-top: 200px;
  // height: 730px;
  height: 100%;
  // background-image: url('../assets/_20201205114004.jpg');
  background-color:#559ef4;
  background-repeat: no-repeat;
  object-fit:contain;
 
}
.login{
    position: relative;
    width: 400px;
    margin: 0 auto;
    border: 3px solid #ccc;
    padding-top: 40px;
    padding-right: 40px;
    border-radius: 15px;
    .el-form-item:nth-child(2){
      margin-bottom: 10px;
    }

    .login-btn
    {
    margin-right: 20px;
    }
    .login_icon{
      position: absolute;
      left: 37px;
      .img{
        width: 40px;
        height: 40px;
        font-size: 32px;
        line-height: 40px;
        text-align: center;
      }
      .img:nth-child(2){
        margin-top: 22px;
      }
    }
}
.none_bottom{
  margin-top: 30px;
}
</style>
